import type { Brand, Country, Locale } from '@seek/melways-sites';
import format from 'format-number';

const displayNames = new Intl.DisplayNames(['en'], { type: 'region' });

const customCountryNames: Record<string, string> = {
  ph: 'the Philippines',
  hk: 'Hong Kong',
};

const normaliseCountryCode = (country: string) => country.toLocaleLowerCase();

const displayBrands: { [key: string]: string } = {
  seek: 'SEEK',
  jobstreet: 'Jobstreet',
  jobsdb: 'Jobsdb',
};

enum AwardsSectionEnum {
  'starawards',
  'sara',

  // Temporary for -test section - to be remove
  'starawards-test',
  'sara-test',
}
export type AwardsSection = keyof typeof AwardsSectionEnum;

export interface getTitleProps {
  brand: Brand;
  section?: string;
  title?: string;
  site?: string;
}

export const currencySymbolByCountry = (country: Country) => {
  const normalisedCountry = normaliseCountryCode(country);
  switch (normalisedCountry) {
    case 'my':
      return 'RM';
    case 'id':
      return 'RP';
    case 'th':
      return '฿';
    case 'ph':
      return '₱';
    default:
      return '$';
  }
};

export const getCountryName = (country: Country): string => {
  try {
    const normalisedCountry = normaliseCountryCode(country);
    if (customCountryNames[normalisedCountry]) {
      return customCountryNames[normalisedCountry];
    }
    return displayNames.of(normalisedCountry.toUpperCase()) || country;
  } catch (e) {
    return country;
  }
};

export const formatCurrency = (amount: number, country: Country) =>
  format({ prefix: currencySymbolByCountry(country) })(amount);

export const formatNumber = (
  value: number | string,
  locale: Locale | undefined,
) => {
  const resolvedLocale = locale && locale !== 'en-ID' ? locale : 'en-AU';
  return new Intl.NumberFormat(resolvedLocale).format(Number(value));
};

// Insert an item between each item in an array
// eg., insertBetweenItems([1, 2, 3], {x}) => [1, {x}, 2, {x}, 3]
export const insertBetweenArrayItems = (arr: any[], x: any) =>
  arr.flatMap((e) => [e, x]).slice(0, -1);

export const resolveBrands = (brand: Brand = 'seek') => displayBrands[brand];

const getAwardsSection = (section?: string): AwardsSection | undefined => {
  if (!section) return undefined;
  const resolvedSection = section?.toLocaleLowerCase();
  return Object.keys(AwardsSectionEnum).includes(resolvedSection)
    ? (resolvedSection as AwardsSection)
    : undefined;
};

const getTitle = ({
  brand = 'seek',
  section,
  title,
  site,
}: getTitleProps): string => {
  const awardsSection = titleHelper.getAwardsSection(section);

  return awardsSection
    ? titleHelper.getTitleForStarAwardsOrSara({ title, section: awardsSection })
    : titleHelper.getTitleBySiteAndBrand({ title, brand, site });
};

const getTitleBySiteAndBrand = ({
  brand = 'seek',
  title,
  site,
}: getTitleProps) =>
  site?.includes('employer')
    ? `${title} | SEEK Employer`
    : `${title} | ${resolveBrands(brand)}`;

const getTitleForStarAwardsOrSara = ({
  title,
  section,
}: {
  title?: string;
  section: AwardsSection;
}) => {
  const titleIncludesHomeOrEmpty =
    !title || title?.toLocaleLowerCase() === 'home';
  const resolvedTitle = titleIncludesHomeOrEmpty ? '' : ` - ${title}`;
  return section === 'starawards'
    ? `SEEK STAR Awards${resolvedTitle}`
    : `SEEK SARA Awards${resolvedTitle}`;
};

export const titleHelper = {
  getTitle,
  getAwardsSection,
  getTitleBySiteAndBrand,
  getTitleForStarAwardsOrSara,
};
