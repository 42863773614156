import { CBoxBackground, CustomDecoration } from '@seek/cmsu-cms-connect';
import type { RenderParams } from '@seek/forms-ui';
import type { UseField } from '@seek/forms-ui/lib/Field/useField';
import type { Language } from '@seek/forms-ui/lib/types';
import { useMelwaysLink } from '@seek/melways-react';
import type { Country } from '@seek/melways-sites';
import {
  badWords,
  disallowHtml,
  isEmail,
  maxLength,
  required,
} from '@seek/validators-js';
import { useTranslations } from '@vocab/react';
import {
  Alert,
  Box,
  Button,
  Dropdown,
  Heading,
  IconPositive,
  Inline,
  Stack,
  Text,
  TextField,
  TextLink,
  Tiles,
} from 'braid-design-system';
import { useState } from 'react';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { BoxWithDecorator } from '../../shared/decorations';
import { getBoxBackgroundProps } from '../PageComponent/helper';
import type { ButtonStyleProps } from '../types';
import translations from './.vocab';
import * as styles from './Form.css';
import type { FormValues } from './SubscriptionForm';

type FormProps = RenderParams<FormValues>;

interface ComponentProps extends FormProps {
  useField: UseField<FormValues, Language>;
  type: string;
  locale: string;
  wptSalesForceApi: string;
  sourceName: string;
  privacyUrl: string;
  country: Country;
  submitBtnStyle?: ButtonStyleProps;
}

type Industry =
  | 'Corporate Business'
  | 'Public sector/government'
  | 'Small to medium business'
  | 'Recruitment';

const getHamiStyling = (isHAMI: boolean) =>
  ({
    formBoxContainer: {
      paddingX: { tablet: isHAMI ? 'xxlarge' : 'xlarge', mobile: 'gutter' },
      paddingY: isHAMI ? 'xxlarge' : 'xlarge',
      ...getBoxBackgroundProps(
        isHAMI ? CBoxBackground.SeekBlueLight800 : CBoxBackground.Brand,
      ),
      customDecoration: isHAMI ? CustomDecoration.TalBannerDeco_2 : undefined,
    },
  } as const);

export const Form = ({
  handleSubmit,
  useField,
  resetForm,
  type,
  wptSalesForceApi,
  sourceName,
  country,
  submitBtnStyle,
}: ComponentProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const { t } = useTranslations(translations);
  const industyList: Industry[] = [
    'Corporate Business',
    'Public sector/government',
    'Small to medium business',
    'Recruitment',
  ];
  const localisedLink = useMelwaysLink();
  const { language } = useCMSUContext();
  const isHAMI = type === 'hiring-advice' || type === 'market-insights';
  const onSubmit = handleSubmit((formValues: FormValues) => {
    fetch(`${wptSalesForceApi}/hirer-subscription`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        ...formValues,
        dateSubscribed: new Date().toISOString(),
        sourceName,
        siteKey: country.toUpperCase(),
      }),
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true);
        } else {
          resetForm();
          setSubscriptionError(true);
        }
      })
      .catch((error) => {
        setSubscriptionError(true);
        resetForm();
        throw error;
      });
  });

  const firstName = useField({
    id: 'firstName',
    validators: [required, badWords, disallowHtml, maxLength] as any,
    validatorProps: {
      max: 40,
    },
  });
  const lastName = useField({
    id: 'lastName',
    validators: [required, badWords, disallowHtml, maxLength] as any,
    validatorProps: {
      max: 40,
    },
  });

  const email = useField({
    id: 'email',
    validators: [required, disallowHtml, isEmail, maxLength] as any,
    validatorProps: {
      max: 255,
    },
  });

  const industry = useField({
    id: 'industry',
    validators: [required],
  });

  const getHeading = () => {
    if (type === 'hiring-advice') {
      return t('Subscribe to Hiring Advice');
    }

    return t('Subscribe to Market Insights');
  };

  const getSubHeading = () => {
    if (type === 'hiring-advice') {
      return t('Get expert hiring advice delivered to your inbox.');
    }

    return t('Get expert market insights delivered to your inbox.');
  };

  const link = (path: string) =>
    localisedLink({
      language,
      path,
      country,
      product: country === 'au' || country === 'nz' ? 'candidate' : 'employer',
    });
  return (
    <form onSubmit={onSubmit}>
      <BoxWithDecorator
        className={styles.containerWrapper}
        borderRadius={{ mobile: 'none', tablet: 'large' }}
        {...getHamiStyling(isHAMI).formBoxContainer}
      >
        <Stack align="left" space="medium">
          <Heading align="left" level="2">
            {getHeading()}
          </Heading>
          {isSubmitted ? (
            <Box paddingTop="medium">
              <Text icon={<IconPositive />}>{t('Thanks for subscribing')}</Text>
            </Box>
          ) : (
            <>
              <Box>
                <Text align="left">{getSubHeading()}</Text>
              </Box>
              <Box className={styles.container}>
                <Stack space="medium">
                  <Tiles
                    columns={{
                      mobile: 1,
                      tablet: 1,
                      desktop: 2,
                      wide: 2,
                    }}
                    space="large"
                  >
                    <TextField
                      id="first-name-field"
                      label={t('First Name')}
                      message={!firstName.valid && firstName.errorMessage}
                      tone={!firstName.valid ? 'critical' : 'neutral'}
                      value={firstName.value}
                      onChange={firstName.onChange}
                    />

                    <TextField
                      id="last-name-field"
                      label={t('Last Name')}
                      message={!lastName.valid && lastName.errorMessage}
                      tone={!lastName.valid ? 'critical' : 'neutral'}
                      value={lastName.value}
                      onChange={lastName.onChange}
                    />
                    <TextField
                      id="email-text-field"
                      label={t('Email Address')}
                      message={!email.valid && email.errorMessage}
                      tone={!email.valid ? 'critical' : 'neutral'}
                      value={email.value}
                      onChange={email.onChange}
                    />
                    <Dropdown
                      id="industry-drop-down"
                      aria-label="drop-down"
                      label={t('Primary Industry')}
                      value={industry.value}
                      onChange={industry.onChange}
                      tone={industry.errorMessage ? 'critical' : 'neutral'}
                      message={industry.errorMessage}
                      placeholder={t('Select an Industry')}
                    >
                      {industyList.map((item) => (
                        <option key={item}>{t(item)}</option>
                      ))}
                    </Dropdown>
                  </Tiles>
                  <Text size="xsmall" id="terms-unsubscribmessage">
                    {t(
                      'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.',
                      {
                        collectionTextLink: (children) => (
                          <TextLink
                            href={link('/collection-notice')}
                            weight="weak"
                          >
                            {children}
                          </TextLink>
                        ),
                        privacyTextLink: (children) => (
                          <TextLink href={link('/privacy')} weight="weak">
                            {children}
                          </TextLink>
                        ),
                      },
                    )}
                  </Text>
                  <Inline space={'small'}>
                    <Button
                      size="standard"
                      tone="brandAccent"
                      type="submit"
                      id="submitBtn"
                      {...submitBtnStyle}
                    >
                      {t('Subscribe')}
                    </Button>
                  </Inline>
                </Stack>
                {subscriptionError && (
                  <Box
                    paddingTop="medium"
                    paddingBottom="medium"
                    id="subscriptionError"
                  >
                    <Alert tone="critical">
                      <Text>{t('Something Wrong')}</Text>
                    </Alert>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Stack>
      </BoxWithDecorator>
    </form>
  );
};
